import { useApiReportTemplates } from '@reports/composables'
import { Product, type ReportTemplate } from '@reports'
import type { Sector } from '@register'

export const TEMPLATE_CUSTOM: ReportTemplate = {
  id: 'custom',
  name: 'Unsaved custom report',
  description: 'This is a custom report that has not been saved',
  isCustomTemplate: true,
  product: Product.LEASE_REGISTER,
}

export function useTemplates() {
  const divisionId = getCurrentDivisionId()
  const query = useRouteQuery<{
    fieldIds?: string[]
    sector?: Sector
    product?: Product
    template?: string
  }>()
  const { data, isPending: isPendingTemplates } =
    useApiReportTemplates(divisionId)
  const templates = computed<ReportTemplate[]>(() => {
    const _templates: ReportTemplate[] = [...(data.value ?? [])]

    if (query.value.template === 'custom') {
      _templates.push(createCustomTemplate(query.value))
    }

    return _templates
  })

  return {
    templates,
    isLoading: computed(() => isPendingTemplates.value),
  }
}

export function createCustomTemplate({
  fieldIds,
  sector,
  product,
}: {
  fieldIds?: string[]
  sector?: Sector
  product?: Product
}): ReportTemplate {
  return {
    ...TEMPLATE_CUSTOM,
    template: {
      fields: (fieldIds ?? []).map((id: string) => ({ id })),
    },
    product,
    sector,
  }
}
